<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="feature.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="feature.setSlug(feature.slug || feature.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="feature.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="feature.setSlug(feature.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="feature.description"
              type="textarea"
              name="description"
              label="Description"
              counter="280"
              max="280"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="feature.tooltip" type="textarea" name="tooltip" label="Tooltip" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="feature.type" type="select" :items="FEATURE_TYPES" name="type" label="Type" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input id="icon" v-model="feature.icon" type="text" name="icon" label="Icon" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input id="code" v-model="feature.code" type="text" name="code" label="Code" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              id="sort"
              v-model="feature.sort"
              type="number"
              dense
              outlined
              name="sort"
              label="Display Sequence"
              required
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="feature.title" type="text" name="title" label="Name display in list a space" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="feature.hideFrom.filter"
              type="checkbox"
              name="hideFrom.filter"
              label="Hide from filter"
            />
            <vx-input
              v-model="feature.hideFrom.create"
              type="checkbox"
              name="hideFrom.create"
              label="Hide from list a space"
            />
            <vx-input
              v-model="feature.hideFrom.view"
              type="checkbox"
              name="hideFrom.view"
              label="Hide from space details"
            />

            <vx-input
              v-model="feature.showPreview"
              type="checkbox"
              name="showPreview"
              label="Show all non selected attributes (strike through)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Feature } from '@tutti/models';
import { FEATURE_TYPES } from '@tutti/constants';
import { FeatureService } from '@tutti/services';

export default {
  data: () => ({
    tab: 0,
    feature: new Feature(),
    loading: false,
    FEATURE_TYPES,
  }),

  created() {
    this.tab = this.$route?.params?.tab || 0;

    this.getFeature();
  },

  methods: {
    async getFeature() {
      if (this.$route.params.id) {
        const response = await FeatureService.getById(this.$route.params.id);
        if (response) {
          this.feature = new Feature(response.data);
          this.$setTitle(this.feature.name);
        }
      }
    },

    async save(previous) {
      this.loading = true;

      const response = await FeatureService.save(this.feature.get());
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('feature', response.data._id));
        }
      }

      this.loading = false;
    },
  },
};
</script>
