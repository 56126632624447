var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.feature.setSlug(_vm.feature.slug || _vm.feature.name);
      }
    },
    model: {
      value: _vm.feature.name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "name", $$v);
      },
      expression: "feature.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.feature.setSlug(_vm.feature.slug);
      }
    },
    model: {
      value: _vm.feature.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "slug", $$v);
      },
      expression: "feature.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    model: {
      value: _vm.feature.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "description", $$v);
      },
      expression: "feature.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "tooltip",
      "label": "Tooltip"
    },
    model: {
      value: _vm.feature.tooltip,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "tooltip", $$v);
      },
      expression: "feature.tooltip"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "items": _vm.FEATURE_TYPES,
      "name": "type",
      "label": "Type"
    },
    model: {
      value: _vm.feature.type,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "type", $$v);
      },
      expression: "feature.type"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "icon",
      "type": "text",
      "name": "icon",
      "label": "Icon"
    },
    model: {
      value: _vm.feature.icon,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "icon", $$v);
      },
      expression: "feature.icon"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "code",
      "type": "text",
      "name": "code",
      "label": "Code"
    },
    model: {
      value: _vm.feature.code,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "code", $$v);
      },
      expression: "feature.code"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "sort",
      "type": "number",
      "dense": "",
      "outlined": "",
      "name": "sort",
      "label": "Display Sequence",
      "required": ""
    },
    model: {
      value: _vm.feature.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "sort", $$v);
      },
      expression: "feature.sort"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "title",
      "label": "Name display in list a space"
    },
    model: {
      value: _vm.feature.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "title", $$v);
      },
      expression: "feature.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hideFrom.filter",
      "label": "Hide from filter"
    },
    model: {
      value: _vm.feature.hideFrom.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.feature.hideFrom, "filter", $$v);
      },
      expression: "feature.hideFrom.filter"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hideFrom.create",
      "label": "Hide from list a space"
    },
    model: {
      value: _vm.feature.hideFrom.create,
      callback: function callback($$v) {
        _vm.$set(_vm.feature.hideFrom, "create", $$v);
      },
      expression: "feature.hideFrom.create"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "hideFrom.view",
      "label": "Hide from space details"
    },
    model: {
      value: _vm.feature.hideFrom.view,
      callback: function callback($$v) {
        _vm.$set(_vm.feature.hideFrom, "view", $$v);
      },
      expression: "feature.hideFrom.view"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "checkbox",
      "name": "showPreview",
      "label": "Show all non selected attributes (strike through)"
    },
    model: {
      value: _vm.feature.showPreview,
      callback: function callback($$v) {
        _vm.$set(_vm.feature, "showPreview", $$v);
      },
      expression: "feature.showPreview"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }